<template>
  <div class="couponManage">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="onLoad"
    >
      <van-sticky>
        <van-row type="flex" align="center" class="coupon-top-row">
          <van-col class="tabItem" @click="changeType(1)">
            <section :class="['itemTop', type === 1 ? 'itemTopAction' : '']">
              <p class="itemTopName">{{ $t.couponInterpret.state1 }}</p>
            </section>
            <div
              :class="['itemBorder', type === 1 ? 'itemBorderAction' : '']"
            ></div>
          </van-col>
          <van-col class="tabItem" @click="changeType(2)">
            <section :class="['itemTop', type === 2 ? 'itemTopAction' : '']">
              <p class="itemTopName">{{ $t.couponInterpret.state2 }}</p>
            </section>
            <div
              :class="['itemBorder', type === 2 ? 'itemBorderAction' : '']"
            ></div>
          </van-col>
          <van-col class="tabItem" @click="changeType(3)">
            <section :class="['itemTop', type === 3 ? 'itemTopAction' : '']">
              <p class="itemTopName">{{ $t.couponInterpret.state3 }}</p>
            </section>
            <div
              :class="['itemBorder', type === 3 ? 'itemBorderAction' : '']"
            ></div>
          </van-col>
          <van-col class="tabItem" @click="changeType(4)">
            <p :class="['tabItemTotal', type === 4 ? 'itemTopAction' : '']">
              {{ $t.couponInterpret.state4 }}
            </p>
            <div
              :class="['itemBorder', type === 4 ? 'itemBorderAction' : '']"
            ></div>
          </van-col>
        </van-row>
      </van-sticky>
      <div class="list">
        <coupon-list
          :listCoupon="listCoupon"
          :type="type"
          @addShopCoupon="addShopCoupon"
          @shareShopCoupon="shareShopCoupon"
        ></coupon-list>
      </div>
    </van-list>
  </div>
</template>
<script>
import Vue from "vue";
import { Sticky, Row, Col, List } from "vant";
Vue.use(Sticky).use(Row).use(List).use(Col);
Vue.use(List).use(Sticky);
import CouponList from "./components/couponList";

export default {
  name: "Coupon",
  components: {
    CouponList,
  },
  data() {
    return {
      listCoupon: [],
      type: 1,
      loading: true,
      //params
      pageIndex: 1,
      finished: false,
    };
  },
  created() {
    this.changeType(1);
  },
  mounted() {
    window.document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        document.getElementsByClassName("coupon-top-row")[0].style.paddingTop =
          "calc(15px + env(safe-area-inset-top))";
      } else {
        document.getElementsByClassName("coupon-top-row")[0].style.paddingTop =
          "15px";
      }
    });
  },
  methods: {
    resetParams() {
      this.pageIndex = 1;
      this.finished = false;
    },
    changeType(type) {
      this.loading = true;
      this.type = type;
      this.resetParams();
      this.getList();
    },
    getList() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      var request = null;
      if (this.type == 1) {
        param = {};
        request = this.$api.shop.getCouponListByPage(param);
      } else if (this.type == 2) {
        param = {};
        request = this.$api.shop.getShopShareCouponList(param);
      } else {
        param = {
          CouponState: this.type == 3 ? 2 : 3,
        };
        request = this.$api.shop.getShopCouponListByPage(param);
      }
      request
        .then((res) => {
          const data = res.data;
          this.listCoupon = data;
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    onLoad() {
      this.loading = false;
      this.finished = true;
    },
    addShopCoupon(id) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        ACId: id,
      };
      this.$api.shop
        .addShopCoupon(param)
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: res.message,
          });
          this.getList();
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
    shareShopCoupon(data) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = data;
      this.$api.shop
        .shareShopCoupon(param)
        .then((res) => {
          this.getList();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: res.message,
          });
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.couponManage {
  width: 100%;
  font-size: 10px;
  .list {
    width: 100%;
  }
  .coupon-top-row {
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    padding-top: 15px;
    transition: padding-top 0.1s;
    .tabItem {
      width: 25%;
      text-align: center;
      font-size: 14px;
      color: #333333;
      .tabItemTotal {
        margin: 0;
        padding: 0;
      }
      .itemTop {
        @include puiblicFlex;
        text-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        .itemTopName {
          margin: 0;
          padding: 0;
        }
        .itemTopNum {
          margin: 0;
          padding: 0;
        }
      }
      .itemTopAction {
        color: #43cd9a;
      }
      .itemBorder {
        height: 4px;
        width: 30px;
        // background-color: #43cd9a;
        margin: 0 auto;
        border-radius: 10px;
        margin-top: 10px;
        opacity: 0;
      }
      .itemBorderAction {
        background-color: #43cd9a;
        opacity: 1;
      }
    }
  }
}
</style>
