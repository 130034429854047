import Main from './index.vue'
import Vue from 'vue'
let SelectionConstructor = Vue.extend(Main)

let instance

const Selection = function (options) {
  if (instance) { return instance }
  options = options || {}
  instance = new SelectionConstructor({
    data: options,
    methods: {
      onClose () {
        Selection.close()
      }
    }
  })
  instance.$mount()
  // instance.$mount(instance.$el)
  document.body.appendChild(instance.$el)
  return instance
}

Selection.close = function () {
  instance = null
}

export default Selection
