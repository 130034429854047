<template>
  <transition name="selection-fade" @after-leave="handleAfterLeave">
    <div class="shipping-address-selection" v-show="visible">
      <header>
        <van-nav-bar
          :left-arrow="true"
          @click-left="onClickLeft"
          :title="$t.couponInterpret.selectionTitle"
        ></van-nav-bar>
      </header>

      <transition name="selection-fade">
        <fans-list
          :isSelection="true"
          @actionDidTap="actionDidTap"
          @onClose="dismiss"
          class="address-list"
          ref="list"
        ></fans-list>
      </transition>
    </div>
  </transition>
</template>

<script>
import fansList from "@view/store/fans/fans";
export default {
  name: "CouponSelection",
  components: {
    fansList,
  },
  data() {
    return {
      selectedId: null,
      addressId: null,
      addressTitle: "",
      visible: false,
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
    this.selectedId = this.id;
    this.addressTitle = this.$t.meta.addAddress;
  },
  destroyed() {
    document.body.style.overflow = null;
  },
  methods: {
    actionDidTap(fan) {
      this.onSelected(fan);
      this.dismiss();
    },
    onClickLeft() {
      this.dismiss();
    },
    handleAfterLeave() {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
    onSelected(fan) {
      //传入参数
    },
    show() {
      this.visible = true;
    },
    dismiss() {
      this.visible = false;
      if (typeof this.onClose === "function") {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped>
.selection-fade-enter,
.selection-fade-leave-active {
  transform: translateX(100%);
  opacity: 0;
}
.shipping-address-selection {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f6f6f6;
  z-index: 999;
  transition: all 0.3s;
  overflow-y: hidden !important;
  margin-top: env(safe-area-inset-top);
}

.address-list,
.add-address {
  height: 100%;
  overflow: scroll;
}

.add-address {
  transition: all 0.3s;
  position: relative;
}

header {
  overflow-y: hidden !important;
  background: linear-gradient(left, #71d283, #01aaa3);
}
header /deep/ .van-nav-bar {
  background: transparent;
  line-height: 2.5;
}
header /deep/ .van-nav-bar__content {
  overflow-y: hidden !important;
}
header /deep/ .van-nav-bar__title {
  color: #fff;
  font-size: 1.2rem;
}
header /deep/ .van-nav-bar__arrow {
  color: #fff;
  font-size: 2rem;
}
header /deep/ .van-hairline--bottom::after {
  display: none;
}
</style>
